import React from "react";

/**
 * Data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = (editScreen) => {
    const dataColumns = [
        {
            field: "row_no",
            title: "Row no",
            editable: false,
            show: false,
            width: "250px",
            minResizableWidth: 150,
            filterable: true,
        },
        {
            field: "uc_ossi_af_id",
            title: "Application Flow ID",
            editable: false,
            show: true,
            width: "250px",
            minResizableWidth: 50,
            cell: (props) => (
                <td
                    className="edit-link"
                    onClick={() => {
                        editScreen(props.dataItem);
                    }}
                >
                    {props.dataItem.uc_ossi_af_id}
                </td>
            ),
        },
        {
            field: "uc_ossi_descr",
            title: "Description",
            editable: false,
            show: true,
            width: "150px",
            minResizableWidth: 50,
        },
        {
            field: "uc_ossi_grp",
            title: "Group",
            editable: false,
            show: true,
            width: "200px",
            minResizableWidth: 50,
        },
    ];

    return dataColumns;
};
export default DataColumns;
