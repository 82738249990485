import React from "react";
import { deleteAllCookies } from "smart-react";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { USER_DATA, TENANT_ID } from "../../../constants/applicationConstants";
import { listProducts } from "../../Products/Services/ProductService";
import { getUserProfile } from "../../Profile/Services/ProfileService";
export const AuthContext = React.createContext();

/**
 * Return Auth Context Object
 * @returns
 */
export const useAuth = () => React.useContext(AuthContext);

/**
 * AuthContext Provider to handle User Data
 * @param {*} param0
 * @returns
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [userAuthObj, setUserAuthObj] = React.useState(null);
  const [tenantID, setTenantID] = React.useState(
    localStorage.getItem(TENANT_ID) ?? ""
  );
  const [featureData, setFeatureData] = React.useState(null);
  const [profileData, setProfileData] = React.useState(null);

  /**
   * Set User Data
   * @param {*} userdt
   */
  const updateUser = (userdt) => {
    setUser({
      ...userdt,
      full_name: `${userdt.first_name} ${userdt.last_name}`,
    });
  };

  /**
   * Set User Tenant
   * @param {*} userdt
   */
  const updateTenant = (tenant) => {
    localStorage.setItem(TENANT_ID, tenant);
    setTenantID(tenant);
  };

  // check for access token in session storage
  // if token is not expired, return true
  const checkTokens = () => {
    const { accessToken } = getTokensFromStorage();
    if (accessToken) {
      // check token for expiration
      const expired = Date.now() > accessToken.tokenExp * 1000;
      if (!expired) {
        return true;
      }
      return false;
    }
    return false;
  };

  // clear session storage upon logout
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    window.location.replace("/login");
  };

  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    if (!user && accessToken) {
      const userDetail = sessionStorage.getItem(USER_DATA);
      updateUser(JSON.parse(userDetail));
    }
  }, []);

  React.useEffect(() => {
    if (!featureData && user) {
      (async () => {
        const userData = { ...user, IsPrimary: false };
        const productsData = await listProducts({ user: userData });
        setFeatureData(productsData);
      })();
    }
    if (!profileData && user) {
      const { accessToken } = getTokensFromStorage();
      (async () => {
        const response = await getUserProfile({
          accessToken,
          user,
        });
        setProfileData(response?.data);
      })();
    }
  }, [user]);
  return (
    <AuthContext.Provider
      value={{
        logout,
        checkTokens,
        user,
        userAuthObj,
        updateUser,
        updateTenant,
        tenantID,
        getTokensFromStorage,
        featureData,
        setFeatureData,
        profileData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
