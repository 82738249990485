import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback, TextInput } from "smart-react";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { buildNotification, GenerateNotification } from "smart-react";
import {
  createFlow,
  updateFlow,
  listFlows,
} from "../Services/AppFlowService";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Field } from "@progress/kendo-react-form";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../constants/eventDataTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import CommandEditor from "../../Core/Components/CommandEditor/CommandEditor";
import { useAuth } from "../../Core/Context/AuthContext";
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import TenantDropdown from "../../Core/Components/TenantDropdown/TenantDropdown";
import {
  ValidatePermissions,
  findTenant,
  findTenantById,
} from "../../../Utils/Auth/ValidatePermissions";
import { isValidate, isFromValid } from "../../../Utils/Forms/Form";
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
} from "../../../constants/notificationMessages";
import DesktopLayout from "./../Components/Layout/DesktopLayout";
import DescriptionTextArea from "../../Core/Components/DescriptionTextArea/DescriptionTextArea";
/**
 * Add New Floe main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add New Flow component.
 */
const AddAppFlow = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [isTextArea, setIsTextArea] = React.useState(false);
  const [tenant, setTenant] = React.useState(findTenantById(tenantID));
  const [loading, setLoading] = React.useState(false);
  const [isEditor, setIsEditor] = React.useState(false);
  const [validateForm, setValidateForm] = React.useState(false);
  const [flowState, setFlowState] = React.useState(state);
  const [selected, setSelected] = React.useState(0);
  const [formData, setFormData] = React.useState({
    tenant_id: tenantID,
    tenant_name: tenant?.TenantName,
    uc_ossi_prod_id: "REDPRAIRIE",
    uc_ossi_af_id : "",
    uc_ossi_descr: "",
    uc_ossi_grp: "",
  });
  const requiredFields = ["uc_ossi_af_id", "uc_ossi_descr", "tenant_name"];
  const [editorField, setEditorField] = React.useState({
    name: "",
    value: "",
    label: "",
  });
  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  React.useEffect(() => {
    if (state) {
      const { accessToken } = getTokensFromStorage();
      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await listFlows({
          accessToken: accessToken,
          limit: "50",
          offset: 1,
          filter: [
            {
              ColumnName: "uc_ossi_af_id",
              Op: "eq",
              ColumnValue: dataSet?.uc_ossi_af_id,
            },
          ],
          isServiceWorker: false,
        });
        const flowData = response.flows[0];
        setFormData(flowData);
        setLoading(false);
      })();
      setFormData({ ...dataSet });
      setFlowState({ ...state, dataSet: dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = findTenantById(tenantID);
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        tenant_id: tenantID,
        tenant_name: tempTenant?.TenantName ? tempTenant?.TenantName : "",
      }));
    }
  }, [tenantID]);

  /**
   * Handle Selected Tab
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * onSave
   * submit the Flow data to api
   */
  const onSave = async ({ isExit = false,isContinue = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      uc_ossi_af_id: formData.uc_ossi_af_id,
      uc_ossi_grp: formData.uc_ossi_grp,
      uc_ossi_descr: formData.uc_ossi_descr,
    };
    let response;
    let method;

    if (state?.edit) {
      method = updateFlow;
      notificationMetaData = UPDATE_MESSAGE;
    } else {

      method = createFlow;
      notificationMetaData = CREATE_MESSAGE;
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: "ApplicationsAutestAF",
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
            buildNotification({
              title: ERROR_MESSAGE?.title,
              description: response.Message,
              style: ERROR_MESSAGE?.style,
            }),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
            buildNotification(notificationMetaData),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
        if (isContinue) {
          navigate(`/flow`, {
            state: {
              edit: true,
              dataSet: response?.Payload,
            },
          });
        }
        if (isExit) {
          navigate("/application-flow");
        }
      }
    } catch (error) {
      GenerateNotification(
          buildNotification(ERROR_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to Flow listing screen
   */
  const onExit = () => {
    navigate("/application-flow");
  };
  return (
      <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
      >
        <div className="container mx-auto">
          <React.Suspense fallback={<Loader />}>
            {loading && <Loader />}
            {isEditor ? (
                <CommandEditor
                    label={"Syntax*"}
                    renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                    validateForm={validateForm}
                    formData={formData}
                    setFormData={setFormData}
                    detailsDataModified={detailsDataModified}
                    isEditor={isEditor}
                    setIsEditor={setIsEditor}
                    editorField={editorField}
                    setEditorField={setEditorField}
                    smHeight={"100px"}
                    lgHeight={"70vh"}
                />
            ) : (
                <>
                  <div className="add-flow main-page-section add-page-section">
                    <Card className="tophead">
                      <CardHeader>
                        <div className="action-buttons-container">
                          <Link
                              className="action-icon-wrapper !k-d-block"
                              to={`/application-flow`}
                          >
                            <FontAwesomeIcon
                                className="k-mr-2 k-pt-3"
                                icon={faArrowLeft}
                            />
                          </Link>
                          <div>
                            <p className="display-text k-mb-0">
                              Flow
                              {": " + (state?.edit ? formData?.uc_ossi_af_id || "" : formData?.uc_ossi_af_id || "")}
                            </p>
                            {state?.edit ? (
                                // If `state.edit` is true, render the content inside
                                <>
                                  {formData?.uc_ossi_descr && (
                                      // Check if `formData.uc_ossi_descr` exists, then display it in a styled span
                                      <span className="k-d-block k-mb-2 k-pl-1">
                                        {formData.uc_ossi_descr}
                                      </span>
                                  )}
                                </>
                            ) : null}
                          </div>
                        </div>
                      </CardHeader>
                    </Card>
                    <Card className="k-mt-3 detail-card">
                      <CardHeader>
                        <h6 className="card-title-secondary">Details</h6>
                      </CardHeader>
                      <CardBody className="detail-card-body">
                        <div>
                          <Form
                              onSubmit={onSave}
                              render={(formRenderProps) => (
                                  <div className="card-content">
                                    <div className="k-d-flex single-field-row single-row-60">
                                      <div
                                          className={`field-wrapper ${!state?.edit && "prefix-field-wrapper"} ${isValidate(validateForm, formData?.tenant_name) ? "field-invalid" : "field-valid"}`}
                                      >
                                        <TenantDropdown
                                            textField="TenantName"
                                            label="Tenant"
                                            dataItemKey="TenantId"
                                            className="tenant-dropdown k-align-self-end"
                                            tenant={tenant}
                                            setTenant={setTenant}
                                            defaultItem={{
                                              TenantName: "Select Tenant ...",
                                              disabled: true,
                                            }}
                                            editMode={state?.edit ? "ReadOnly" : ""}
                                            filterable={true}
                                            tenantFormId="tenant_id"
                                            tenantFormName="TenantName"
                                            setFormData={setFormData}
                                            requiredField={true}
                                        />
                                        {isValidate(
                                            validateForm,
                                            formData?.tenant_name
                                        ) && <Error>This field cannot be empty</Error>}
                                      </div>
                                    </div>
                                    <div className="k-d-flex single-field-row single-row-100">
                                      <div
                                          className={`field-wrapper ${!state?.edit && "prefix-field-wrapper"} ${isValidate(validateForm, formData.uc_ossi_af_id) ? "field-invalid" : "field-valid"}`}>
                                        <Field
                                            key={"uc_ossi_af_id"}
                                            id={"uc_ossi_af_id"}
                                            name={"uc_ossi_af_id"}
                                            label={"Application Flow ID"}
                                            data={formData?.uc_ossi_af_id}
                                            onChange={handleInputChange}
                                            type={"text"}
                                            component={TextInput}
                                            renderTypes={state?.edit ? "ReadOnly" : ""}
                                            optional={false}
                                            requiredField={true}
                                        />
                                        {isValidate(
                                            validateForm,
                                            formData.uc_ossi_af_id
                                        ) && <Error>This field cannot be empty</Error>}
                                      </div>
                                      <div
                                          className={`field-wrapper`}>
                                        <Field
                                            key={"uc_ossi_grp"}
                                            id={"uc_ossi_grp"}
                                            name={"uc_ossi_grp"}
                                            data={formData?.uc_ossi_grp}
                                            onChange={handleInputChange}
                                            label={"Group Name"}
                                            type={"text"}
                                            component={TextInput}
                                            renderTypes={
                                              !detailsDataModified ? "ReadOnly" : ""
                                            }
                                            optional={false}
                                            requiredField={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="k-d-flex single-field-row single-row-100">
                                      <div
                                          className={`field-wrapper field-wrapper-description field-wrapper-100 ${isValidate(validateForm, formData?.uc_ossi_descr) ? "field-invalid" : "field-valid"}`}>
                                        <DescriptionTextArea
                                            onChange={handleInputChange}
                                            label={"Description"}
                                            renderTypes={
                                              !detailsDataModified ? "ReadOnly" : ""
                                            }
                                            validateForm={validateForm}
                                            formData={formData}
                                            setFormData={setFormData}
                                            detailsDataModified={detailsDataModified}
                                            isTextArea={isTextArea}
                                            setIsTextArea={setIsTextArea}
                                            editorField={{
                                              name: "uc_ossi_descr",
                                              value: formData?.uc_ossi_descr,
                                              label: "Description",
                                            }}
                                            setEditorField={setEditorField}
                                            row={2}
                                            requiredField={true}
                                        />
                                        {isValidate(
                                            validateForm,
                                            formData?.uc_ossi_descr
                                        ) && <Error>This field cannot be empty</Error>}
                                      </div>
                                    </div>
                                  </div>
                              )}
                          />
                        </div>
                      </CardBody>
                    </Card>
                    {detailsDataModified && (
                        <Card className="k-mt-3">
                          <CardHeader>
                            <div className="k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0">
                              <>
                                <Button
                                    icon={"cancel"}
                                    onClick={() => onExit()}
                                    type="button"
                                >
                                  Cancel
                                </Button>
                                <SplitButton
                                    text="save"
                                    themeColor={"primary"}
                                    onButtonClick={() => onSave({ isExit: false, isContinue: true })}
                                    onItemClick={handleSaveSplit}
                                >
                                  <SplitButtonItem text="Save & Close" />
                                </SplitButton>
                              </>
                            </div>
                          </CardHeader>
                        </Card>
                    )}
                    {flowState?.edit && (
                        <Card className="k-mt-3 detail-grid-section">
                          <CardBody className="!k-px-0">
                            <DesktopLayout
                                handleSelect={handleSelect}
                                selected={selected}
                                detailsDataModified={detailsDataModified}
                                state={flowState}
                            />
                          </CardBody>
                        </Card>
                    )}
                  </div>
                </>
            )}
          </React.Suspense>
        </div>
      </ErrorBoundary>
  );
};
export default AddAppFlow;