import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { App_Flow } from "../../../constants/apiRoutes";

/**
 * list AppFlow.
 * @returns {Promise} - A promise that resolves with the list of AppFlow or rejects with an error.
 * @param props
 */
export const listFlows = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${App_Flow}/filter`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    flows: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Application Flow.
 * @returns {Promise} - A promise that resolves with the creation of Application Flow or rejects with an error.
 * @param data
 */
export const createFlow = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${App_Flow}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Application Flow.
 * @returns {Promise} - A promise that resolves with the update of Application Flow or rejects with an error.
 * @param data
 */
export const updateFlow = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${App_Flow}`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Application Flow.
 * @returns {Promise} - A promise that resolves with the update of Application Flow or rejects with an error.
 * @param data
 */
export const deleteFlow = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${App_Flow}?prodId=${data?.uc_ossi_prod_id}&afId=${data?.uc_ossi_af_id}`,
    method: "DELETE",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 * @param request
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
